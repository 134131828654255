import { request } from '@/assets/js/http.js'
export var SearchList = [
  { labe: '物料编号', code: 'uleId', type: 'input' },
  // { labe: '物料描述', code: 'ruleRemark', type: 'input' },
  // {
  //   labe: '物料类别',
  //   code: 'ruleType',
  //   type: 'select',
  //   option: [
  //     { label: '普通', value: '1' },
  //     { label: '专用', value: '2' },
  //     { label: 'AVAP', value: '3' }
  //   ]
  // },
  { labe: '供应商', code: 'uleSupplier', type: 'input', placeholder: '编码/名称' },
  {
    labe: '库位类型',
    code: 'rule_storage_type',
    type: 'select',
    option: [
      { label: '外协库', value: '1' },
      { label: '返修库', value: '2' },
      { label: '工厂库', value: '3' },
      { label: 'VMI库', value: '4' }
    ]
  },
  {
    labe: '库位',
    code: 'rule_storage',
    type: 'inputAutoComplete',
    triggerOnFocus: false,
    fetchSuggestions: function (queryString, cb) {
      var scs = []
      var str = queryString.toString()
      // 匹配库位
      request('/api/livraison/entrepot/getEntrepotDataList?' + 'str=' + str, 'get').then((res) => {
        res.data.forEach(item => {
          scs.push({
            value: item.entrPosiName
          })
        })
        cb(scs)
      })
    }
  }
]
export var SearchData = {
  ulrId: '',
  ruleRemark: '',
  ruleType: '',
  uleSupplier: '',
  rule_storage_type: '',
  rule_storage: ''
}
export var tableField = [
  { label: '物料编号', code: 'materialCode', type: 'input', width: '80%' },
  { label: '物料描述', code: 'materialDesc', type: 'input', width: '100%' },
  // {
  //   label: '物料类别',
  //   code: 'materialType',
  //   type: 'function',
  //   width: '60%',
  //   handle: (index, data) => {
  //     return data.materialType === '1' ? '普通' : data.materialType === '2' ? '专用' : data.materialType === '3' ? 'AVAP' : ''
  //   }
  // },
  { label: '单位', code: 'units', type: 'input', width: '40%' },
  { label: '账面库存', code: 'unrestrictedStock', type: 'input', width: '55%' },
  { label: '冻结库存', code: 'freezeTheInventory', type: 'input', width: '55%' },
  { label: '供应商编码', code: 'ruleSuppliernNumber', type: 'input', width: '65%' },
  { label: '供应商名称', code: 'ruleSupplier', type: 'input', width: '100%' },
  { label: '经营范围', code: 'scopeOfBusiness', type: 'input', width: '55%' },
  { label: '库位', code: 'storage', type: 'input', width: '60%' },
  {
    label: '库位类型',
    code: 'storageType',
    type: 'function',
    width: '55%',
    handle: (index, data) => {
      return data.storageType === '1' ? '外协库' : data.storageType === '2' ? '返修库' : data.storageType === '3' ? '工厂库' : data.storageType === '4' ? 'VMI库' : ''
    }
  }
]

export var tableData = []
