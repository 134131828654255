<template>
  <div>
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
    <global-table :tableField="tableField" :tableData="tableData" :paginationData="paginationData" @pageChange="pageChange"
    ></global-table>
  </div>
</template>

<script>
import ListSearch from '@/components/actionList/ListSearch.vue'
import GlobalTable from '@/components/actionList/GlobalTable.vue'
import { SearchList, SearchData, tableField, tableData } from './js/setting.js'
import { request } from '@/assets/js/http.js'
export default {
  components: { ListSearch, GlobalTable },
  name: 'InventoryManagement',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      tableField: tableField,
      tableData: tableData,
      pageNum: 1,
      paginationData: {
        pageSize: pageSize,
        total: 0
      },
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },

  created () {
    const userType = this.$store.state.loginInfo
    if (userType.userType === 1) {
      this.$router.push({
        path: '/InventoryDelivery/ManagingInventory'
      })
    }
    this.myBidData()
  },
  methods: {
    buttonClick: function (data) {
      this.$router.push({ params: { viewName: 'BidNoticeRating' } })
    },
    // 获取采购库存-库存管理数据列表
    myBidData () {
      const obj = {
        pageNum: this.pageNum,
        pageSize: this.paginationData.pageSize,
        ...this.childData.searchData
      }
      request('/api/order/PurchasingSystem/queryEkpoyList', 'POST', obj).then((res) => {
        if (res.code === '200') {
          if (res.data.code === '200') {
            this.tableData = res.data.data.rows
            if (this.paginationData.total !== res.data.data.total) {
              this.paginationData.total = res.data.data.total
            }
          } else {
            this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'error'
            })
          }
        }
      })
    },
    // 查询
    onSearch (data) {
      this.pageNum = 1
      this.myBidData()
    },
    // 清空
    clearSearch (data) {
      this.pageNum = 1
      this.myBidData()
    },
    // 分页
    pageChange (data) {
      this.pageNum = data
      this.myBidData()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tablePadding.scss';
</style>
